// src/components/Hero.js

import React from 'react';
import './Hero.css'; // Make sure to import the CSS file

const Hero = () => {
  return (
    <div className="hero">
      <img src="/assets/Hero+01.png" alt="Hero" className="hero-image" />
      <div className="hero-content">
        <h1 className="hero-title">Welcome to <span className="animated-text">SkyCorals</span></h1>
        <h2>
          Your go-to place for AI & ML, <br />
          Content Creation, Publishing,<br />
          and Full-stack Cloud Solutions.
        </h2>
      </div>
    </div>
  );
};

export default Hero;
