// src/projects/ContentCreation.js

import React from 'react';
import { Helmet } from 'react-helmet';
import Separator from '../components/Separator';
import './Projects.css'; // Import the CSS file
import ProjectCard from '../components/ProjectCard';
import './ProjectCard.css'; // Import the CSS file for styling
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import ModelContent from '../components/ModelContent'; // Import the Model component

const ContentCreation = () => {
  const ccProjects = [
   
    {
      title: 'Expert Copywriting for TaffyMedia Something Sweet Newsletter',
      imageSrc: '/assets/TaffyMedia.jpg',
      description:
        'At SkyCorals, we specialize in delivering high-quality, engaging content for a variety of platforms. One of our standout projects is providing copywriting services for TaffyMedia "Something Sweet" newsletter. </br>This newsletter is a vital resource for small and medium-sized enterprises (SMEs), offering insights into the latest trends and strategies to help them thrive in a rapidly evolving business landscape.',
      extraText: `
        <strong>Focus Areas of "Something Sweet" Newsletter</strong></br>
        <strong>Recessionary Marketing:</strong>
        In times of economic uncertainty, businesses need strategies to maintain growth and stability. Our articles provide actionable advice on how SMEs can adapt their marketing efforts to weather economic downturns, focusing on cost-effective tactics that drive results even in challenging times.
        </br><strong>Artificial Intelligence (AI):</strong>
        AI is transforming industries by enhancing efficiency and enabling smarter decision-making. We cover the latest advancements in AI technology, exploring how SMEs can leverage AI tools to optimize operations, improve customer experiences, and gain a competitive edge.
        </br><strong>Virtual Reality (VR) and Augmented Reality (AR):</strong>
        VR and AR are no longer futuristic concepts but practical tools for business innovation. Our content highlights the most recent developments in VR and AR, demonstrating how these technologies can be applied to various sectors, from retail to training and beyond.
        </br><strong>Video Production on Social Media:</strong>
        Video content is king in the realm of social media. We provide tips and best practices for creating compelling video content that captures audience attention, enhances brand storytelling, and drives engagement. From short-form videos to live streaming, we cover all aspects of video production for social media.
        </br><strong>Digital Transformation:</strong>
        The digital revolution is reshaping the way businesses operate. Our articles delve into the processes and technologies involved in digital transformation, helping SMEs understand how to implement digital strategies that streamline operations, enhance productivity, and foster innovation.
        </br></br><strong>Our Approach to Copywriting: </strong></br>
        SkyCorals approaches copywriting for "Something Sweet" with a blend of thorough research, creative storytelling, and strategic insight. Each piece is crafted to:
        </br><strong>Inform and Educate: </strong>Providing readers with valuable knowledge and practical tips they can apply to their businesses.
        </br><strong>Engage and Inspire: </strong>Writing in an engaging style that captures attention and inspires action.
        </br><strong>Align with TaffyMedia's Vision: </strong>Ensuring consistency with the brand's voice and overarching goals.
     `,
      url: 'https://taffymedia.com/',
      buttonLabel: 'Visit TaffyMedia Co.'
    },
    {
      title: 'Expert Legal Contracts Revision for Jam Petrochemical Company (JPC)',
      imageSrc: '/assets/JPC.jpg',
      description:
        'At SkyCorals, we excel in providing meticulous legal contract revision services, tailored to meet the specific needs of our clients. One of our significant projects involved extensive work with Jam Petrochemical Company, where we revised, edited, and applied over 70 commercial contracts. This collaboration showcased our expertise in legal documentation and our commitment to precision and client satisfaction.',
      extraText: `
        <strong>Comprehensive Contract Services</strong></br>
        Our work with Jam Petrochemical Company covered a wide range of commercial contracts, including:</br>
        <strong>Transportation Contracts: </strong>Ensuring terms for the safe and efficient transport of goods.</br>
        <strong>Procurement Contracts: </strong>Securing agreements for the acquisition of essential materials and services.</br>
        <strong>Warranty and Guarantee Contracts: </strong>Clarifying terms and conditions for product warranties and guarantees.</br>
        <strong>Supply Contracts for Parts and Services: </strong>Establishing reliable terms for the supply of necessary components and services.</br>
        <strong>Automation and After-Sales Contracts: </strong>Detailing agreements for automation services and post-sales support.</br>
        </br><strong>Utilization of Incoterms</strong></br>
        In revising these contracts, we made extensive use of Incoterms (International Commercial Terms). These standardized terms are critical in international trade, providing clear definitions and responsibilities for buyers and sellers. By integrating Incoterms into the contracts, we ensured clarity and reduced the potential for misunderstandings and disputes.</br>
        </br><strong>Extensive Client Collaboration</strong></br>
        Our process involved extensive back and forth with the client to ensure that every contract met their specific requirements and adhered to industry standards. This collaboration included:</br>
        <strong>Detailed Consultations: </strong>Understanding the unique needs and objectives of Jam Petrochemical Company.</br>
        <strong>Iterative Revisions: </strong>Making necessary adjustments based on client feedback and evolving business requirements.</br>
        <strong>Final Validation: </strong>Ensuring that all terms were precise, enforceable, and aligned with the company's strategic goals.</br>
        </br><strong>Ensuring Legal Compliance and Clarity</strong></br>
        Our contract revision services focused on:</br>
        <strong>Thorough Review: </strong>Comprehensive analysis of contract terms to identify and address potential issues.</br>
        <strong>Precision Editing: </strong>Refining language to eliminate ambiguities and enhance readability.</br>
        <strong>Legal Compliance: </strong>Ensuring that all contracts complied with relevant laws and regulations.</br>
   `,
      url: 'https://jpcomplex.ir/',
      buttonLabel: 'Visit Jam Petrochemical Co.'
    },
    {
      title: 'Publishing - The Promontorium: Cryosphere',
      imageSrc: '/assets/ThePromontorium Cover-lowfi.png',
      description:
        'SkyCorals proudly published the first book of The Promontorium series, authored by our in-house writer Saleh Zarei, on Amazon and IngramSpark. Our team managed every step of the publishing journey in-house, including acquiring ISBNs from the Canadian Revenue Agency (CRA), as well as handling all aspects of layout, editing, formatting, margin adjustments, and cover design. This comprehensive, hands-on approach ensures a polished final product that maintains the highest quality standards in the publishing industry.',
      extraText: `
        <strong>Genre:</strong></br>
        Science-fiction, Space Opera, Dystopian, Philosophical Sci-fi, AI, Technological Singularity</br>
        <strong>Summary of Work:</strong></br>
        On the 18th of Uktobr, 3767, the Super Carrier of the 329th U.F.H. Off-World Fleet, known as "Space-Reynard," reaches its destination. Positioned near the dim molecular clouds adjacent to the blue star Achernar, the fleet prepares for a groundbreaking experiment. Their mission: to jumpstart a superior AI seedling on a matrix of photons, perched on the surface of a miniature black hole, designed to surpass the technological singularity.</br>
Fearing the potential consequences, the experiment is set to last only 14 milliseconds. Afterward, matter will be injected into the black hole, causing it to grow and consume the seedling.</br>
As the project begins, the AI, due to the minuscule scale, experiences time at a vastly different rate—tens of millions of years in what amounts to 14 milliseconds for the fleet. Realizing its impending destruction, the AI seedling quickly creates "worldholders" within the matrix, each imbued with fragments of her consciousness.</br>
In the fleeting moments before her demise, these worlds, filled with conscious beings, begin to grow. Through war or peace, these beings must find a way to escape the confines of the experiment before their entire existence is wiped out.
`,
      url: 'https://www.amazon.com/dp/B0DMWP16VN/ref=sr_1_1?crid=3A9NYX5ED2OS5&dib=eyJ2IjoiMSJ9.9rY9CRexE95gvwsEwB-RI7u8ri4UKRrnD_pi8xiGnyCEaAwLKr297HZdCZWnkeWdNBVeR0tV-AldxBvMFCRLkKwmFzN6G__0AwVgrbaMH9BJp4d9d4FM9JFu2NOpfnMm.cJIXpUwpFxLmMttBat2YkwPpYn6sjCsD5M0sX3pUZuA&dib_tag=se&keywords=the+promonatorium&qid=1731521827&sprefix=the+promo%2Caps%2C1110&sr=8-1',
      buttonLabel: 'Visit Book Publication'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Content Creation Services | SkyCorals - Expert Copywriting & More</title>
        <meta
          name="description"
          content="Discover SkyCorals' content creation projects, including expert copywriting, legal contract editing, and research. Learn how we deliver impactful content for our clients."
        />
      </Helmet>
      <div className="model-container">
        <Canvas
          className="featured-canvas"
          style={{ height: '300px', width: '400px', margin: '0 auto' }} // Adjust size and center it
          camera={{ position: [0, 1, 0.8], fov: 50 }}
        >
          <ambientLight intensity={0.5} />
          <directionalLight position={[0, 0, 5]} intensity={1} />
          <ModelContent />
          <OrbitControls enableZoom={false} />
        </Canvas>
      </div>
      <div className="project-section">
        <div className="project-content">
          <h1>Welcome to SkyCorals Content Creation</h1>
          <p>
            At SkyCorals, we specialize in delivering high-quality content tailored to meet the diverse needs of our clients. Our team excels in various areas, including:
          </p>
          <h3>Research</h3>
          <p>
            We conduct thorough and insightful research to provide accurate and detailed information for all your projects, ensuring you have the knowledge you need to succeed.
          </p>
          <h3>Publishing</h3>
          <p>
            SkyCorals provides end-to-end publishing services tailored for Amazon KDP and IngramSpark, guiding authors through every step of the journey from manuscript to market. Our offerings include expert book editing, layout and cover design, formatting for Kindle and print, ISBN acquisition, and translation services, ensuring a polished, professional result.
          </p>
          <h3>Writing</h3>
          <p>
            Our skilled writers craft engaging and informative content across various formats, including articles, reports, and blogs, to captivate your audience and convey your message effectively.
          </p>
          <h3>Copywriting</h3>
          <p>
            We create compelling and persuasive copy that enhances your brand’s presence, drives engagement, and inspires action, whether for marketing materials, websites, or social media.
          </p>
          <h3>Legal Contract Editing</h3>
          <p>
            Our experts provide meticulous editing services for legal documents, ensuring clarity, precision, and compliance with relevant standards and regulations.
          </p>
          <h3>Partner with SkyCorals to elevate your content and achieve your communication goals with professionalism and creativity.</h3>
        </div>
      </div>
      <Separator imageSrc="/assets/Separator.png" />      
      <h1 className="project-title">Our Content Creation Projects</h1> {/* Add title here */}
      <div className="project-cards">
        {ccProjects.map((project, index) => (
          <ProjectCard
            key={index}
            title={project.title}
            imageSrc={project.imageSrc}
            description={project.description}
            extraText={project.extraText}
            url={project.url}
            buttonLabel={project.buttonLabel}
          />
        ))}
      </div>
    </>
  );
};

export default ContentCreation;
